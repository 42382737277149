import { Col, Row } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';

import { OverlinedSubTitle } from '../shared';
import { TextCol } from '../../components/index/helpers';

const SpacedRow = styled(Row)`
    padding-bottom: 30px;
    padding-top: 30px;
`;

export const TextBlock = ({ title, children, txtcolor }) => {
    return (
        <SpacedRow>
            <Col xs={9} md={5} xl={3}>
                {title && <OverlinedSubTitle txtcolor={txtcolor}>{title}</OverlinedSubTitle>}
            </Col>
            <TextCol xl={{ offset: 1, size: 8 }} txtcolor={txtcolor}>
                {children}
            </TextCol>
        </SpacedRow>
    );
};
