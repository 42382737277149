import styled from 'styled-components';
import { TEXT_FONT } from '../../constants';

export const Wrapper = styled.div`
    background-color: ${props => props.color};
    padding-bottom: 60px;
    padding-top: 60px;
    font-family: ${TEXT_FONT};

    @media (max-width: 768px) {
        padding-top: 60px;
    }

    ul {
        margin-top: 30px;
    }

    h4 {
        margin-top: 20px;
    }
`;
